@media only screen and (max-width: 950px) {
  .app {
    display: none;
  }
}

.mobile {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (min-width: 951px) {
  .mobile {
    display: none;
  }
}


.mobile h1, .mobile h3 {
  text-align: center;
}

.mobile h1 {
  font-size: 15vw;
  line-height: 130%;
}

.mobile h3 {
  font-size: 7.5vw;
}

.mobile-information ul {
  list-style: none;
  text-align: center;
  margin: 3vh 0 0 0;
  padding: 0;
}

.mobile-information ul li {
  display: inline-block;
  margin: 0 10px;
}

.mobile-information ul li a {
  color: #fff;
}

.mobile-information ul li a .fa {
  font-size: 7.5vw;
}

a {
  text-decoration: none;
  transition: all .35s;
  transition-timing-function: ease-in-out;
}

a h4 {
  opacity: .4;
}

a:hover h4 {
  opacity: 1;
}

.credentials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.credentials__display {
  position: relative;
  width: 50vw;
  left: 10vw;
}

.experience {
  padding: 10vw;
}

.experience__header {
  position: relative;
}

.experience__timeline {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 80vw;
  position: relative;
  margin-top: 10vw;
}

.experience__timeline::before {
  content: "";
  background: linear-gradient(#1E2224,transparent);
  width: 50%;
  height: 6rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.experience__timeline::after {
  content: "";
  background: linear-gradient(transparent,#1E2224);
  width: 50%;
  height: 6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.experience__timeline__entry {
  position: relative;
  padding-bottom: 6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.experience__timeline__entry:last-child {
  padding-bottom: 12vw;
}

.experience__timeline__line {
  position: absolute;
  left: 19.48vw;
  width: 1px;
  height: 100%;
  background: #61dafb;
  opacity: .2;
}

.dot {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin: 0 8vw;
  position: relative;
  background-color: #61dafb;
  transition: .2s ease-out;
  opacity: .8;
}

.dot.Plx--active {
  transform: scale(2);
  opacity: 1;
}

.date {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 4vw 0;
  width: 11vw;
  font-weight: 400;
}

.month {
  color: #fff;
  opacity: .6;
  display: inline;
  padding-right: .2em;
  font-size: 2.2vw;
  text-transform: capitalize;
}

.year {
  color: #fff;
  opacity: .2;
  display: inline;
  font-size: 2.2vw;
}

.contact {
  height: 30vh;
  box-sizing: border-box;
  padding: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.social-buttons {
  height: 90px;
  margin-left: 2vw;
}

.button {
  text-decoration: none;
  transition: all .35s;
  transition-timing-function: cubic-bezier(
    0.310,
    -0.105,
    0.430,
    1.590
  );
  display: inline-block;
  background-color: #1E2325;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
  opacity: .99;
}

.button.github::before {
  background-color: #fff;
}

.button.github .fa {
  color: #fff;
}

.button.linkedin::before {
  background-color: #fff;
}

.button.linkedin .fa {
  color: #fff;
}

.button:focus::before, .button:hover::before {
  top: -10%;
  left: -10%;
}

.button:focus .fa, .button:hover .fa {
  color: #000;
  transform: scale(1);
}

.button::before {
  transition: all .35s;
  transition-timing-function: cubic-bezier(
    0.310,
    -0.105,
    0.430,
    1.590
  );
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  transform: rotate(45deg);
  top: 90%;
  left: -110%;
}

.button .fa {
  font-size: 38px;
  vertical-align: middle;
  transform: scale(.8);
  transition: all .35s;
  transition-timing-function: cubic-bezier(
    0.310,
    -0.105,
    0.430,
    1.590
  );
}

h1, h2, h3, h4 {
  color: #fff;
  font-size: 10vw;
  font-weight: 700;
  margin: 0;
  line-height: 90%;
  transition: all .35s;
  transition-timing-function: cubic-bezier(
    0.310,
    -0.105,
    0.430,
    1.590
  );
}

h2 {
  font-size: 7.5vw; 
  line-height: 120%;
}

h3 {
  font-size: 5vw;
  text-transform: lowercase;
  font-weight: 300;
  opacity: .4;
  margin: 0 0 0 0.5vw;
  /*color: #61dafb;*/
}

h4 {
  font-size: 2vw;
  font-weight: 400;
}

h4 span {
  font-weight: 300;
  opacity: .4;
}